.app {
  &.app-layout-common {
    .app-content {
      height: 100%;
      display: flex;
      justify-content: center;

      .app-page-content {
        text-align: center;

        .alert {
          fill: $danger;
        }
      }
    }
  }

  &.app-layout-full {
    &.app-layout-authorized {
      padding: 0 0 0 15rem;
      padding-top: 4rem;
      &.centered {
        padding-left: 0;
        .app-content {
          margin: 0 auto 1.625rem;
        }
      }
    }

    .app-sidebar {
      position: fixed;
      top: 4rem;
      bottom: 0;
      left: 0;
      border-right: 0.063rem solid #dee6ed;
      box-shadow: inset -0.625rem 0 0.625rem -0.75rem rgba(17, 0, 34, 0.12);
    }

    .app-tab-nav {
      background: #fff;
      border-bottom: 0.0625rem solid $border-color;
      overflow: hidden;
    }

    .app-content {
      margin: 0 auto 6.625rem;
      .centered-content {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding-right: 3rem;
  padding-left: 3rem;
}

.page-title,
.title {
  font-size: 1.375rem;
  color: $caption-color;
  word-break: break-word;
}

.title {
  line-height: 1.625rem;
  margin-bottom: 0.5rem;
}

.page-wrap {
  padding-top: 2.75rem;

  .page-title {
    font-weight: 500;
    line-height: 2.6rem;
    margin-bottom: 1.875rem;
  }

  .types {
    margin-bottom: 1.375rem;
  }

  .grid-data {
    border: 1px solid #dee5ed;
    box-sizing: border-box;
    box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
    padding: 1.875rem;
    border-radius: 0.5rem;
    .item {
      padding: 1.25rem 0;
      border-bottom: 1px solid #dee6ed;
      &:last-of-type {
        border-bottom: none;
      }
    }

    svg {
      margin-right: 0.375rem;
    }
    .jobSeekersCount,
    .employment-status-count {
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.938rem;
      color: $text-color-lighter;
      position: relative;
      padding-right: 0.438rem;
    }
    .employment-status-count {
      padding-left: 0.438rem;
      &:after {
        content: '';
        border-right: 0.125rem solid #dee6ed;
        position: absolute;
        top: -0.313rem;
        left: 0;
        width: 0.188rem;
        height: 1.5rem;
      }
    }
    .actions-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .actions {
    margin-bottom: 2.875rem;
    min-height: 1.5rem;
    button {
      margin-right: 1rem;
      &:last-of-type {
        margin-right: 0;
      }
    }

    &.no-margin {
      margin-bottom: 0;
    }

    .date,
    .id {
      font-size: 0.75rem;
      color: $text-color-lighter;
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  .sharedCount {
    span {
      background: $light-background-color;
      border-radius: 0.25rem;
      color: $text-color-lighter;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.875rem;
      padding: 0.313rem;
    }
    svg {
      width: 0.875rem;
      margin-top: -0.125rem;
    }
  }

  .text-label {
    span {
      color: $text-color-lighter;
      font-weight: 600;
      font-size: 0.75rem;
    }
  }

  .status {
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 0.75rem;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
    padding: 0.188rem 0.25rem;
    word-break: normal;

    &.confirmed,
    &.published,
    &.active {
      background: #d7f9e7;
      color: $theme-color-green;
    }
    &.unconfirmed,
    &.draft,
    &.ignored,
    &.inactive,
    &.pending {
      background: $light-gray;
      color: $text-color-lighter;
    }
    &.archived {
      background: #ffdedd;
      color: #ff564f;
    }
  }

  .details {
    .email,
    .phone,
    .address,
    .organizationName,
    .program,
    .employment-status,
    .property {
      font-size: 0.75rem;
      color: $text-color;
    }
    .property {
      margin-bottom: 0.5rem;
    }
  }

  .sharedCount,
  .actions {
    text-align: right;
  }

  .table-filters {
    box-sizing: border-box;
    padding: 0 0 1.875rem 0;
  }
  .table-filters .table-tabs {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .table-filters {
    .form-group,
    .search-component-wrapper {
      margin-bottom: 0;
    }
  }

  @media (max-width: 991px) {
    .table-filters {
      margin-top: 1rem;
      .form-group {
        margin-bottom: 0;
      }
    }
    .job-base-table-component,
    .job-seekers-table-component,
    .my-programs-table-component,
    .employer-managers-table-component {
      .title {
        margin-top: 2.6rem;
      }
    }
  }

  .limit-width-search-field {
    align-items: flex-start;
    margin-bottom: 1rem;
    .rd__container,
    .search-component-wrapper {
      max-width: 538px;
    }
  }
}

.tabWrap {
  margin-bottom: 0;
  display: inline-block;
  background: $light-background-color;
  padding-top: 0.375rem;
  padding-right: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.375rem;
  border-radius: 0.375rem;
  line-height: 1rem;

  &.full-width {
    .tabs.row {
      margin-right: 0;
      margin-left: 0;
      .col {
        padding: 0;
      }
      button {
        width: 100%;
      }
    }
  }
}

.tabs {
  background: $light-background-color;
  vertical-align: middle;
  .tab {
    padding-top: 0.375rem;
    padding-right: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.375rem;
    font-weight: 500;
    font-size: 0.813rem;
    line-height: 1rem;
    text-align: center;
    color: $text-color-lighter;
    position: relative;
    border-radius: 0.375rem;
    margin-right: 0.188rem;
    border: none;
    background-color: $light-background-color;
    box-shadow: none;
    &:after {
      content: '';
      border-right: 0.125rem solid #dee6ed;
      position: absolute;
      top: 0.125rem;
      right: -0.188rem;
      width: 0.188rem;
      height: 1.5rem;
    }
    &.active {
      background: #fff;
      font-weight: 600;
      color: $theme-color-blue;
      box-shadow: 0rem 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
      &:before {
        content: '';
        border-left: 0.13rem solid #f3f8fb;
        position: absolute;
        top: 0.125rem;
        left: -0.125rem;
        width: 0.188rem;
        height: 1.5rem;
      }
      &:after {
        content: '';
        border-right: none;
      }
    }
    &:last-of-type {
      &:after {
        content: '';
        border-right: none;
      }
    }
  }
}

.page-wrap,
.join-modal {
  .title {
    margin-bottom: 0.625rem;
  }
  .name {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: $caption-color;
  }
  .name-description {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: $text-color-lighter;
  }
  .description {
    margin-bottom: 1.25rem;
  }
  .types {
    margin-bottom: 0.625rem;
    b {
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
      color: $text-color;
      font-weight: 700;
    }
  }
  .type {
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: $text-color;
  }

  .title-wrap {
    display: flex;
    align-items: center;
  }
}

.dot {
  padding-left: 1.25rem;
  position: relative;
  &::before {
    content: '';
    background: $input-color-light;
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 50%;
    top: 0.313rem;
    left: 0.5rem;
    position: absolute;
  }
}

.tag {
  background: $light-background-color;
  border: 1px solid $border-color;
  box-sizing: border-box;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
  border-radius: 1.25rem;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 0.75rem;
  text-align: center;
  letter-spacing: 0.01em;
  color: $theme-color-blue;
  padding: 0.188rem 0.375rem;
  margin-top: 0;
  margin-right: 0.375rem;
  margin-bottom: 0.625rem;
  display: inline-block;
  word-break: break-word;
  &:last-of-type {
    margin-right: 0;
  }
  &.capitalize {
    text-transform: capitalize;
  }
}

.sections {
  margin-bottom: 2.5rem;
  border-bottom: 0.125rem solid #dee6ed;
  .btn-white {
    box-shadow: none;
    &:not(.selected) {
      font-weight: 400;
    }
  }
  button.btn {
    position: relative;
    padding-left: 0;
    padding-right: 1.5rem;
    color: $text-color-lighter;
    font-weight: 500;
    box-shadow: none;
    &.selected {
      color: $theme-color-blue;
      font-weight: 600;
      &:before {
        content: '';
        width: 100%;
        width: calc(100% - 1.5rem);
        height: 0.25rem;
        background: $theme-color-blue;
        left: 0;
        bottom: -0.125rem;
        position: absolute;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
    }
    &:hover {
      color: $light-blue;
    }
  }
}

.loader {
  width: 3rem;
  height: 3rem;
}

.app {
  &.app-layout-full.app-layout-authorized {
    &.mobile {
      padding: 4rem 0 0 0;
      .app-sidebar.opened {
        width: 100%;
      }
      .app-sidebar:not(.opened) {
        display: none;
      }
    }
  }
}

.section-title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 2.5rem;
  color: $caption-color;
  margin-bottom: 1.5rem;
}

.item {
  &-info {
    &-logo {
      display: flex;
      max-width: 7.5rem;
      min-width: 7.5rem;
      justify-content: center;
      margin-right: 1.25rem;
      align-items: center;

      img {
        max-height: 7.5rem;
        max-width: 7.5rem;
        image-rendering: -webkit-optimize-contrast;
      }
      svg {
        height: 3rem;
        width: 3rem;
      }
    }
    &-body {
      margin-top: 0.75rem;
    }
    &-details {
      font-size: 0.75rem;
      font-weight: 500;
      color: $text-color-lighter;

      .property {
        line-height: 21px;
      }
      .property.address {
        margin-right: 0.5rem;
      }
      .property.range {
        white-space: nowrap;
        svg {
          margin-top: -4px;
        }
      }
    }
    &-hires,
    &-benefits,
    &-programs,
    &-description {
      font-size: 0.75rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      color: $text-color;
      span {
        font-weight: 600;
      }
    }
  }
  &-name {
    color: $caption-color;
    font-size: 1.0625rem;
    font-weight: 600;
  }
  &-modal-link {
    cursor: pointer;
    color: $text-color;
    font-weight: 500;
    font-size: 0.8125rem;
    margin-bottom: 0.6rem;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      color: $text-color;
    }
  }
  &-address {
    display: flex;
    margin-top: 0.25rem;
    &-web,
    &-location {
      margin-right: 0.5rem;
      color: $text-color-lighter;
      font-size: 0.75rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  .item-actions-block {
    line-height: 18px;
    .info {
      flex-basis: 100%;
      display: flex;
      flex-wrap: wrap;
      span {
        display: block;
        color: $text-color-lighter;
        font-weight: 400;
        font-size: 0.75rem;
        margin-top: 0.25rem;

        @media (min-width: 992px) {
          display: flex;
          flex-basis: 100%;
          justify-content: flex-end;
          margin-left: 0.25rem;
        }

        @media (max-width: 991px) {
          &:not(:first-child)::before {
            display: inline-block;
            content: '|';
            margin: 0 0.25rem;
          }
        }
      }

      @media (min-width: 992px) {
        justify-content: flex-end;
      }
    }
  }

  .created-at {
    color: $text-color-lighter;
    font-weight: 400;
    font-size: 0.75rem;
    margin: 0.125rem 0;
    padding-top: 0.125rem;
  }

  @media (min-width: 991px) {
    .desktop-hidden {
      display: none !important;
    }
    .item-actions-block {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 991px) {
    .mobile-hidden {
      display: none !important;
    }
    .list-row {
      position: relative;
    }
    .list-row .actions.mobile-top,
    .list-row .item-actions.mobile-top {
      width: auto;
      position: absolute;
      top: 0;
      right: 0;
      &.mobile-full-width {
        width: 100%;
        padding: 0 calc(var(--bs-gutter-x) * 0.25);
        .status {
          float: left;
          margin: 0.25rem auto 0.25rem 0;
        }
      }
    }
    .list-row .item-actions.mobile-top {
      top: calc(var(--bs-gutter-x) * 0.4);
    }
    .list-row.job-seekers-table-component {
      .item-actions.mobile-top {
        top: 0;
      }
    }
    .logo-wrap-with-caption {
      align-items: start;
    }
    .logo-with-replacement {
      justify-content: start;
      min-height: 36px;
    }
  }

  .badge {
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 0.75rem;
    text-align: center;
    border: 1px solid $border-color;
    border-radius: 2rem;
    padding: 0.25rem 0.438rem;
    margin: 0 -1px;
    word-break: normal;
    color: $theme-color-blue;
    background: $light-background-color;
    box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
  }
  .property.location.badge {
    margin-right: 0.5rem;
  }
  .item-name .property.location.badge {
    margin-right: 0;
    margin-left: 0.5rem;
    position: relative;
    top: -2px;
  }
}

.item-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0.5rem;

  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }

  .btn.btn-secondary:last-child {
    margin-right: 0;
  }
}

@include media-breakpoint-down(lg) {
  .page-wrap {
    .actions {
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: 991px) {
  .page-wrap {
    .page-title {
      margin-bottom: 0.25rem;
      font-size: 1.125rem;
    }
  }
  .section-title {
    font-size: 1rem;
    line-height: 2rem;
  }
  .tabWrap {
    padding: 0.25rem;
  }
  .tabs {
    .tab {
      font-size: 0.688rem;
      padding: 0.25rem;
    }
  }
  .list-row {
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .page-wrap .actions {
    .date,
    .id {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .page-wrap {
    padding-top: 1.75rem;
  }
}

@media (max-width: 550px) {
  .section-title {
    .tabWrap {
      display: block;
      .tabs {
        display: flex;
        .tab {
          flex: 1 0;
        }
      }
      &.right {
        float: unset;
      }
    }
  }
}

@media (max-width: 991px) {
  .app.app-layout-full {
    .app-content {
      margin: 0 auto 2rem;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  .navbar-header .navbar-brand {
    width: 30%;
    img,
    svg {
      width: 100%;
    }
  }

  .sections {
    margin-bottom: 1rem;
  }

  .section-title {
    margin-bottom: 1rem;
    .right {
      float: unset;
    }
  }

  .item-info-logo {
    max-width: unset;
    min-width: unset;
    margin-bottom: 0.5rem;
  }
}

.navbar-header .navbar-toggler {
  margin-right: 0.5rem;
  border: none;
}
.logo-with-text {
  display: inline;
}
.logo-no-text {
  display: none;
}
.logo-no-text path {
  fill: $theme-color-blue;
}
@media (max-width: 767px) {
  .navbar-header .navbar-brand {
    width: 3rem;
  }
  .navbar-header .navbar-toggler {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .logo-with-text {
    display: none;
  }
  .logo-no-text {
    display: inline;
  }
}

@media (min-width: 992px) {
  .item-info {
    display: flex;
  }
}

.divider {
  border-bottom: 1px solid #dee6ed;
  margin-bottom: 1.875rem;
}

.settings-wrap {
  border: 1px solid #dee6ed;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
  border-radius: 0.5rem;
  padding: 1.9375rem 3.625rem 3.125rem 3.625rem;

  margin-bottom: 2.125rem;

  .form-group {
    margin-bottom: 1.5rem;
  }

  .section-tooltip {
    margin-bottom: 1.1875rem;
    p {
      line-height: 0.9831rem;
      font-weight: 400;
      font-size: 0.8125rem;
      padding: 0.9375rem 0.8125rem 0.625rem 0.8125rem;
      margin: 0;
      background-color: $light-background-color;
      border-radius: 0.375rem;
      color: $text-color-lighter;
    }
    &.predefined-limit-notice {
      p {
        background-color: #fffcdd;
      }
    }
  }
}

.alert {
  &.alert-primary {
    color: $text-color-lighter;
    font-size: 0.875rem;
    border: none;
    background: $light-background-color;
    font-weight: $font-weight-medium;
  }
}
