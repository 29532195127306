@import 'src/styles/variables';

.radio-wrap {
  .radio-element {
    font-size: 0.813rem;
    padding-bottom: 1em;

    &:last-of-type {
      padding-bottom: 0;
    }

    &.radio-inline {
      display: inline-block;
      vertical-align: top;
      max-width: 25%;
      padding-right: 1.5rem;

      label span.label-span {
        align-items: start;
      }
    }
  }

  label {
    cursor: pointer;
    display: inline;
    margin-bottom: 0;
    input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        &:before {
          box-shadow: inset 0 0 0 0.2375em #fff;
          border: 2px solid $theme-color-blue;
          background-color: $theme-color-blue;
        }
      }
    }
    span.label-span {
      display: flex;
      align-items: center;
      transition: 0.05s;
      line-height: 1rem;
      color: $text-color;
      &:before {
        display: flex;
        flex-shrink: 0;
        content: '';
        background-color: #fff;
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 50%;
        margin-right: 0.625rem;
        transition: 0.05s;
        box-shadow: inset 0 0 0 0.125em $border-color;
      }
    }
  }
}
